// import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { getAuthToken } from '@/utils/auth'

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    try {
      const authToken = getAuthToken()

      if (authToken) config.headers.Authorization = `Bearer ${authToken}`
    } catch (err) {
      console.log('Err ::: ', err)
    }

    return config
  },
  function (error) {
    // store.dispatch('setLoad', false)

    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // store.dispatch('setLoad', false)

    // Do something with response data

    response.data.message && store.dispatch('setAlert', { ...response.data.message, show: true })
    return response
  },
  function (error) {
    // store.dispatch('setLoad', false)

    const response = error.response

    response.data.message && store.dispatch('setAlert', { ...response.data.message, show: true })
    // Do something with response error
    return Promise.reject(error)
  }
)

export default instance
